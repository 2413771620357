import React, { lazy, Suspense, useContext } from "react";
import { HelmetProvider } from "react-helmet-async";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  Link,
} from "react-router-dom";
import logo from "./assets/beta_logo.svg";

import { NotificationProvider } from "./context/Notification";
import { AuthProvider, AuthContext } from "./context/AuthContext";
import { FetchProvider } from "./context/FetchContext";
import { DarkModeProvider } from "./context/DarkModeContext";

// import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
// import SignupPage from "./pages/SignupPage";
// import ActivateAccountPage from "./pages/ActivateAccountPage";

const NotFoundPage = () => {
  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center -mt-16">
      <Link to="/">
        <img src={logo} alt="logo" className="w-24 animate-bounce " />
      </Link>
      <h3 className="mt-4 mb-2 text-3xl leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
        Page Not Found!
      </h3>
      <Link
        to="/"
        className="font-semibold text-primary-500 hover:text-primary-600"
      >
        Back to homepage
      </Link>
    </div>
  );
};

const LoadingFallback = () => {
  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <img src={logo} alt="logo" className="w-24 animate-bounce" />
    </div>
  );
};

const MyProfilePage = lazy(() => import("./pages/MyProfilePage"));
const SettingsPage = lazy(() => import("./pages/SettingsPage"));
const CoursesPage = lazy(() => import("./pages/CoursesPage"));
const CoursePage = lazy(() => import("./pages/CoursePage"));
const AdminPage = lazy(() => import("./pages/AdminPage"));

const UnauthenticatedRoutes = () => {
  const auth = useContext(AuthContext);

  if (auth.isAuthenticated()) {
    return <Redirect to="/courses" />;
  }

  return (
    <Switch>
      <Route exact path="/login">
        <LoginPage />
      </Route>
      {/* <Route path="/signup">
        <SignupPage />
      </Route> */}
      {/* <Route path="/auth/activate/:token">
        <ActivateAccountPage />
      </Route> */}
      <Route exact path="/auth/password/forgot">
        <ForgotPasswordPage />
      </Route>
      <Route path="/auth/password/reset/:token">
        <ResetPasswordPage />
      </Route>
      <Route exact path="/">
        <Redirect to="/login" />
        {/* <HomePage /> */}
      </Route>
      <Route path="*">
        <NotFoundPage />
      </Route>
    </Switch>
  );
};

const AuthenticatedRoute = ({ children, ...rest }) => {
  const auth = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={() => (auth.isAuthenticated() ? children : <Redirect to="/" />)}
    />
  );
};

const SubscribeRoute = ({ children, ...rest }) => {
  const auth = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={() =>
        auth.isAuthenticated() && auth.isSubscribe() ? (
          children
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

const AdminRoute = ({ children, ...rest }) => {
  const auth = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={() =>
        auth.isAuthenticated() && auth.isAdmin() ? (
          children
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

const AppRoutes = () => {
  return (
    <>
      <Suspense fallback={<LoadingFallback />}>
        <Switch>
          <AuthenticatedRoute exact path="/my-profile">
            <MyProfilePage />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/settings">
            <SettingsPage />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/courses">
            <CoursesPage />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/courses/:subject">
            <CoursesPage />
          </AuthenticatedRoute>
          <SubscribeRoute path="/course/:courseId">
            <CoursePage />
          </SubscribeRoute>
          <AdminRoute path="/admin">
            <AdminPage />
          </AdminRoute>
          <UnauthenticatedRoutes />
        </Switch>
      </Suspense>
    </>
  );
};

function App() {
  return (
    <Router>
      <NotificationProvider>
        <DarkModeProvider>
          <HelmetProvider>
            <AuthProvider>
              <FetchProvider>
                <AppRoutes />
              </FetchProvider>
            </AuthProvider>
          </HelmetProvider>
        </DarkModeProvider>
      </NotificationProvider>
    </Router>
  );
}

export default App;

import React, { createContext, useState } from "react";
import { AnimatePresence } from "framer-motion";
import { Notification } from "../components/common/Notification";

const NotificationContext = createContext({
  notificationDetail: null,
  setNotificationDetail: null,
  NOTIFICATION_DEFAULT: null,
});
const { Provider } = NotificationContext;

const NotificationProvider = ({ children }) => {
  const NOTIFICATION_DEFAULT = {
    show: false,
    title: "",
    message: "",
    status: "info",
  };

  const [notificationDetail, setNotificationDetail] = useState(
    NOTIFICATION_DEFAULT
  );

  return (
    <Provider
      value={{
        notificationDetail,
        setNotificationDetail,
        NOTIFICATION_DEFAULT,
      }}
    >
      <AnimatePresence>
        {notificationDetail && notificationDetail.show && (
          <Notification
            title={notificationDetail.title}
            message={notificationDetail.message}
            status={notificationDetail.status}
            close={() => setNotificationDetail(NOTIFICATION_DEFAULT)}
          />
        )}
      </AnimatePresence>
      {children}
    </Provider>
  );
};

export { NotificationContext, NotificationProvider };

import React from "react";

export const ButtonForm = ({ className, onClick, text }) => {
  return (
    <div className={className}>
      <span className="block w-full rounded-md shadow-sm">
        <button
          type="submit"
          className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:border-primary-700 focus:shadow-outline-primary active:bg-primary-700 transition duration-150 ease-in-out"
          onClick={onClick}
        >
          {text}
        </button>
      </span>
    </div>
  );
};

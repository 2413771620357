import React, { createContext, useState, useEffect } from "react";

const DarkModeContext = createContext();
const { Provider } = DarkModeContext;

const DarkModeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    setDarkMode(localStorage.getItem("darkMode") === "true");
  }, []);

  return <Provider value={{ darkMode, setDarkMode }}>{children}</Provider>;
};

export { DarkModeContext, DarkModeProvider };

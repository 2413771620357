import React from "react";
import { Helmet } from "react-helmet-async";

export const TitleComponent = ({ title }) => {
  const defaultTitle = "BetaVideo";

  return (
    <Helmet>
      <title>{title ? title : defaultTitle}</title>
    </Helmet>
  );
};

export const withTitle = ({ component: Component, title }) => (props) => (
  <>
    <TitleComponent title={title} />
    <Component {...props} />
  </>
);

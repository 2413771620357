import React, { useState } from "react";

import { EyeSlash, Eye, Lock } from "../assets/icons";

export const InputPassword = ({ className, value, onChange }) => {
  const [type, setType] = useState("password");

  const handleTypeChange = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };
  return (
    <div className={className}>
      <label
        htmlFor="password"
        className="block text-sm font-medium leading-5 text-gray-700"
      >
        Password
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <Lock />
        </div>
        <input
          id="password"
          type={type}
          required
          value={value}
          onChange={onChange}
          className="appearance-none block w-full px-3 py-2 pl-10 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        />

        <div
          className="absolute inset-y-0 right-0 pr-3 flex items-center"
          onClick={handleTypeChange}
        >
          {type === "password" ? <EyeSlash /> : <Eye />}
        </div>
      </div>
    </div>
  );
};

import React from "react";

import { ResetPassword } from "../components/AuthForm";
import { withTitle } from "../helpers/withTitle";

const ResetPasswordPage = () => {
  return <ResetPassword />;
};

export default withTitle({
  component: ResetPasswordPage,
  title: "BetaVideo | Reset Password",
});

import React, { useState, useContext } from "react";
import { motion } from "framer-motion";

import { NotificationContext } from "../../context/Notification";
// import { forgotPassword } from "../../services/auth";
import { publicFetch } from "../../utils/fetch";

import forgotImg from "./assets/forgot_password.svg";
import { FormHeader, ButtonForm, InputForm } from "./elements";
import { Envelop } from "./assets/icons";

export const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  const { setNotificationDetail } = useContext(NotificationContext);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { data: response } = await publicFetch.post(
        "/auth/forgot-password",
        {
          email,
        }
      );

      setNotificationDetail({
        show: true,
        title: "Sent",
        message: response.data,
        status: "success",
      });
      setEmail("");
    } catch (error) {
      if (error.response) {
        if (error.response.status === 429) {
          setNotificationDetail({
            show: true,
            title: "Error!",
            message: error.response.data,
            status: "error",
          });
        } else {
          const message = error.response.data.error;
          setNotificationDetail({
            show: true,
            title: "Error!",
            message,
            status: "error",
          });
          setEmail("");
        }
      }
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, x: "100vw" }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: "-100vw" }}
      transition={{ duration: 0.3 }}
      className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8"
    >
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <FormHeader text="Forgot password?" imgSrc={forgotImg} />
          <form onSubmit={handleSubmit}>
            <InputForm
              type="email"
              label="Email"
              id="email"
              icon={<Envelop />}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <ButtonForm className="mt-6" text="Send reset password link" />
          </form>
        </div>
      </div>
    </motion.div>
  );
};

import React, { useState, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { motion } from "framer-motion";

import { NotificationContext } from "../../context/Notification";
// import { resetPassword } from "../../services/auth";
import { publicFetch } from "../../utils/fetch";

import resetImg from "./assets/reset_password.svg";
import { FormHeader, ButtonForm, InputPassword } from "./elements";

export const ResetPassword = () => {
  const history = useHistory();
  const { token } = useParams();
  const [password, setPassword] = useState("");

  const { setNotificationDetail } = useContext(NotificationContext);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { data: response } = await publicFetch.put("/auth/reset-password", {
        resetPasswordToken: token,
        newPassword: password,
      });

      setNotificationDetail({
        show: true,
        title: "Success",
        message: response.data,
        status: "success",
      });

      history.push("/login");
    } catch (error) {
      if (error.response) {
        const message = error.response.data.error;
        setNotificationDetail({
          show: true,
          title: "Error!",
          message,
          status: "error",
        });
      }
    } finally {
      setPassword("");
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, x: "100vw" }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: "-100vw" }}
      transition={{ duration: 0.3 }}
      className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8"
    >
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <FormHeader text="Reset Password" imgSrc={resetImg} />
          <form onSubmit={handleSubmit}>
            <InputPassword
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <ButtonForm className="mt-6" text="Save new password" />
          </form>
        </div>
      </div>
    </motion.div>
  );
};

import React from "react";

import { ForgotPassword } from "../components/AuthForm";
import { withTitle } from "../helpers/withTitle";

const ForgotPasswordPage = () => {
  return <ForgotPassword />;
};

export default withTitle({
  component: ForgotPasswordPage,
  title: "BetaVideo | Forgot Password",
});
// export default ForgotPasswordPage;

import React from "react";

export const FormHeader = ({ imgSrc, text }) => {
  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      {imgSrc && (
        <img className="mx-auto h-auto w-32" src={imgSrc} alt="Beta Belajar" />
      )}
      <h2 className="my-4 text-center text-3xl leading-9 font-extrabold text-gray-900">
        {text}
      </h2>
    </div>
  );
};

import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";

import { NotificationContext } from "../../context/Notification";
// import { AuthContext } from "../../context/Auth";
import { AuthContext } from "../../context/AuthContext";
// import { signin } from "../../services/auth";
import { publicFetch } from "../../utils/fetch";

import {
  LoginFormHeader,
  InputForm,
  InputPassword,
  ForgotPasswordLink,
  ButtonForm,
  // LoginGoogle,
  // Checkbox,
} from "./elements";

import { Envelop } from "./assets/icons";

export const LoginForm = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { setNotificationDetail } = useContext(NotificationContext);
  const authContext = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { data: response } = await publicFetch.post("/auth/login", {
        email,
        password,
      });

      authContext.setAuthState(response.data);
      setNotificationDetail({
        show: true,
        title: "Logged in",
        status: "success",
      });
      localStorage.setItem("darkMode", false);
      history.push("/courses");
    } catch (error) {
      if (error.response) {
        if (error.response.status === 429) {
          setNotificationDetail({
            show: true,
            title: "Error!",
            message: error.response.data,
            status: "error",
          });
        } else {
          const message = error.response.data.error;
          setNotificationDetail({
            show: true,
            title: "Error!",
            message,
            status: "error",
          });
          authContext.logout();
        }
      }
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <LoginFormHeader />
      <motion.div
        initial={{ opacity: 0, x: "100vw" }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: "-100vw" }}
        transition={{ duration: 0.3 }}
        // className="mt-8 sm:mx-auto sm:w-full sm:max-w-md"
        className="mt-8 mx-auto w-full max-w-md"
      >
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form onSubmit={handleSubmit}>
            <InputForm
              label="Email"
              type="email"
              id="email"
              icon={<Envelop />}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <InputPassword
              className="mt-6"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <div className="mt-6 flex items-center justify-between">
              <div></div>
              {/* <Checkbox label="Remember me" id="remember_me" /> */}
              <ForgotPasswordLink />
            </div>

            <ButtonForm className="mt-6" text="Sign in" />
          </form>

          {/* <LoginGoogle text="Sign in with Google" /> */}
        </div>
      </motion.div>
    </div>
  );
};

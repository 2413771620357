import React, { createContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { publicFetch } from "../utils/fetch";

const AuthContext = createContext();
const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {
  const history = useHistory();

  const token = localStorage.getItem("token");
  const userInfo = localStorage.getItem("userInfo");
  const expiresAt = localStorage.getItem("expiresAt");

  const [authState, setAuthState] = useState({
    token,
    expiresAt,
    userInfo: userInfo ? JSON.parse(userInfo) : {},
  });

  const setAuthInfo = ({ token, userInfo, expiresAt }) => {
    localStorage.setItem("token", token);
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
    localStorage.setItem("expiresAt", expiresAt);

    setAuthState({
      token,
      userInfo,
      expiresAt,
    });
  };

  const logout = async () => {
    try {
      await publicFetch.delete("/auth/logout");
      localStorage.removeItem("token");
      localStorage.removeItem("userInfo");
      localStorage.removeItem("expiresAt");
      setAuthState({});
      history.push("/login");
    } catch (err) {}
  };

  // When refresh-token not valid
  const clear = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("expiresAt");
    setAuthState({});
    history.push("/login");
  };

  const isAuthenticated = () => {
    if (!authState.token || !authState.expiresAt) {
      return false;
    }

    return true;

    // return new Date().getTime() / 1000 < authState.expiresAt + 10;
  };

  // const isAuthenticated = () => {
  //   if (!authState.expiresAt) {
  //     return false;
  //   }
  //   return new Date() < new Date(authState.expiresAt);
  // };

  const isAdmin = () => {
    return authState.userInfo.role === "admin";
  };

  const isSubscribe = () => {
    const { subscriptionExp } = JSON.parse(localStorage.getItem("userInfo"));
    return new Date(subscriptionExp).getTime() > new Date().getTime();
  };

  const getAccessToken = () => {
    return localStorage.getItem("token");
  };

  const getNewToken = async () => {
    try {
      const { res } = await publicFetch.get("/auth/refresh-token");
      setAuthState({ ...authState, token: res.data.token });
    } catch (err) {
      return err;
    }
  };

  const getNewTokenForRequest = async (failedRequest) => {
    const { data: response } = await publicFetch.get("/auth/refresh-token");

    failedRequest.response.config.headers[
      "Authorization"
    ] = `Bearer ${response.data.token}`;

    localStorage.setItem("token", response.data.token);
    localStorage.setItem("userInfo", JSON.stringify(response.data.userInfo));
    localStorage.setItem("expiresAt", response.data.expiresAt);

    return Promise.resolve();
  };

  return (
    <Provider
      value={{
        authState,
        setAuthState: (authInfo) => setAuthInfo(authInfo),
        logout,
        clear,
        isAuthenticated,
        isAdmin,
        isSubscribe,
        getNewToken,
        getAccessToken,
        getNewTokenForRequest,
      }}
    >
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };

import React from "react";

import { LoginForm } from "../components/AuthForm";
import { withTitle } from "../helpers/withTitle";

const LoginPage = () => {
  return <LoginForm />;
};

export default withTitle({ component: LoginPage, title: "BetaVideo | Login" });
// export default LoginPage;

import React from "react";
import { Link } from "react-router-dom";

export const ForgotPasswordLink = ({ className }) => {
  return (
    <div className={`text-sm leading-5 ${className}`}>
      <Link
        to="/auth/password/forgot"
        className="font-medium text-primary-500 hover:text-primary-600 focus:outline-none focus:underline transition ease-in-out duration-150"
      >
        Forgot your password?
      </Link>
    </div>
  );
};

import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import logo from "../assets/beta_logo.svg";

export const LoginFormHeader = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: "-100vh" }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, x: "-100vw" }}
      transition={{ duration: 0.3 }}
      className="sm:mx-auto sm:w-full sm:max-w-md"
    >
      <Link to="/">
        <img className="mx-auto h-auto w-20" src={logo} alt="Beta Belajar" />
      </Link>
      <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
        Sign in to your account
      </h2>
      {/* <p className="mt-2 text-center text-sm leading-5 text-gray-600 max-w">
        Or{" "}
        <Link
          to="/signup"
          className="font-medium text-primary-500 hover:text-primary-600 focus:outline-none focus:underline transition ease-in-out duration-150"
        >
          Register
        </Link>
      </p> */}
    </motion.div>
  );
};
